import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFound from "../assets/images/svgs/not-found.svg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not found" />
    <section className="container is-vcentered is-centered is-404">
    <NotFound alt="404: Page Not Found" />
    <h1>404: Page Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
)

export default NotFoundPage
